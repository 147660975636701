import React from "react"
import { graphql } from 'gatsby';

import Layout from '../components/layout'
import SEO from '../components/seo'

export default ({ data, location }) => (
  <Layout>
    <SEO
      pagetitle="お探しのページは見つかりませんでした。"
      pagepath={location.pathname}
    />
    <main className="l-content">
      <h1 style={{ padding: "20vh 0", textAlign: "center" }}>お探しのページは見つかりませんでした。</h1>
    </main>
  </Layout>
)

export const query = graphql`
  query {
    key01: file(relativePath: {eq: "about_mv01.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`